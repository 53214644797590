import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { SectionContainer } from '../components/SectionContainer';
import SendButtonSVG from '../resources/send_button.svg';

export default function ArchivePage() {
  return (
    <Layout>
      <SEO title="Contact" />
      <SectionContainer>
        <h1 className="m-auto mb-8 text-3xl font-bold text-center transition-all font-heading sm:text-4xl sm:mb-12">
          <span className="marker-primary">CONTACT</span> US
        </h1>
        <div className="w-full bg-black rounded">
          <div className="flex flex-col sm:flex-row">
            {/* <form className="flex flex-col flex-1 p-8 space-y-4 sm:p-16">
              <div className="flex flex-col space-y-1">
                <label className="font-heading">NAME</label>
                <input type="text" className="px-4 py-2 text-gray-900 bg-white rounded" placeholder="Your Name" />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="font-heading">EMAIL</label>
                <input type="text" className="px-4 py-2 text-gray-900 bg-white rounded" placeholder="Your Email" />
              </div>
              <div className="flex flex-col">
                <label className="font-heading">MESSAGE</label>
                <input
                  type="text"
                  className="px-4 py-2 text-gray-900 bg-white rounded"
                  placeholder="Enter what you want to say!"
                />
              </div>
            </form> */}
            <div className="flex flex-col items-center justify-center flex-1 gap-2 p-8 text-lg font-bold sm:py-16">
              <a href="mailto:wlpo@kibro.co" className="flex-shrink m-8 max-w-[160px] w-auto h-auto">
                <SendButtonSVG className="w-full h-auto" />
              </a>
              <a href="mailto:wlpo@kibro.co" className="text-blue-200 hover:text-blue-400">
                <span>wlpo@kibro.co</span>
              </a>
              <span className="text-center">
                연락이 필요하시면 언제든 연락 주세요! <br />
                최대한 빠르게 답변 드리겠습니다.
              </span>
            </div>
          </div>
        </div>
      </SectionContainer>
    </Layout>
  );
}
